/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useMemo, useState} from 'react';
import useAssessments from '../../hooks/useAssessments';
import {DataGrid, useGridApiRef, esES} from '@mui/x-data-grid';
import studiesConst from '../../constants/studiesConst';
import {Link, useSearchParams} from 'react-router-dom';
import routesConst from '../../constants/routesConst';
import serverRoutesConst from '../../constants/serverRoutesConst';
import classNames from '../../helpers/classNames';
import useSaveGeneralResult from '../../hooks/useSaveGeneralResult';
import {Dialog} from '@mui/material';
import {Toast, ToastContainer} from 'react-bootstrap';
import './AnalystHome.scss';
import StatusLegend from '@/components/StatusLegend/StatusLegend';

export default function AnalystHome() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchInput, setSearchInput] = useState('');
  const [sortModel, setSortModel] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });
  const {
    assessments,
    totalCount,
    isLoading,
    isValidating,
    mutate: mutateAssessments,
  } = useAssessments(
    {
      page: paginationModel.page,
      pageSize: paginationModel.pageSize,
      search: searchParams.get('search'),
      sort: {
        column: sortModel?.[0]?.field && studiesConst.columns[sortModel?.[0]?.field],
        direction: sortModel?.[0]?.sort === 'asc' ? '+' : '-',
      },
      reportAvailable: searchParams.get('reportAvailable'),
    },
    {
      revalidateOnMount: true,
    }
  );
  const [continuousAssessments, setContinuousAssessments] = useState(assessments);
  const [rowCountState, setRowCountState] = useState(totalCount || 0);
  const [savingGeneralResultId, setSavingGeneralResultId] = useState(null);
  const [generalResultStatusSelection, setGeneralResultStatusSelection] = useState('notAnOption');
  const [generalResultObservations, setGeneralResultObservations] = useState('');
  const [isGeneralResultSavingToastShowing, setIsGeneralResultSavingToastShowing] = useState(false);
  const {
    trigger: saveGeneralResult,
    isMutating: isGeneralResultSaving,
    isError: isGeneralResultSaveError,
    reset: resetGeneralResultSaveMutation,
  } = useSaveGeneralResult({assessmentId: savingGeneralResultId});
  const gridRef = useGridApiRef();
  const isPendingStudiesFilterActive = searchParams.get('reportAvailable') === 'false';
  const isFinishedStudiesFilterActive = searchParams.get('reportAvailable') === 'true';

  const isGeneralResultSaveSuccessToastShowing =
    !isGeneralResultSaveError && !isGeneralResultSaving && isGeneralResultSavingToastShowing;

  if (
    assessments &&
    (assessments?.[0]?.assessmentId !== continuousAssessments?.[0]?.assessmentId ||
      assessments?.length !== continuousAssessments?.length)
  ) {
    setContinuousAssessments(assessments);
  }

  useEffect(() => {
    if (isGeneralResultSaving) {
      setIsGeneralResultSavingToastShowing(true);
    }
  }, [isGeneralResultSaving]);

  useEffect(() => {
    if (!isValidating) {
      setContinuousAssessments(assessments);
    }
  }, [isValidating]);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalCount !== undefined ? totalCount : prevRowCountState
    );
  }, [totalCount, setRowCountState]);

  const transformedAssessments = useMemo(
    () =>
      continuousAssessments?.map((assessment) => ({
        col1: assessment.assessmentStatus,
        col2: assessment.assessmentId,
        col3: assessment.applicantName,
        col4: assessment.companyName,
        col5: {
          executiveReport: assessment.executiveReport ?? false,
          detailedReport: assessment.detailedReport ?? false,
        },
        applicantId: assessment.applicantId,
        questionnaireId: assessment.questionnaireId,
        assessmentId: assessment.assessmentId,
        id: assessment.assessmentId,
        modules: assessment.modules,
      })),
    [continuousAssessments]
  );

  const assessmentsRows = transformedAssessments || [];

  const handleGeneralResultDialogClose = () => {
    setGeneralResultStatusSelection('notAnOption');
    setGeneralResultObservations('');
  };

  const handleGeneralResultDialogSave = () => {
    const analysisValues = {
      assessmentStatus: generalResultStatusSelection,
      generalObservations: generalResultObservations || '',
    };
    saveGeneralResult(JSON.stringify(analysisValues)).then(() => mutateAssessments());
    setSavingGeneralResultId(null);
    handleGeneralResultDialogClose();
  };

  const handleGeneralResultDialogCancel = () => {
    setSavingGeneralResultId(null);
    setGeneralResultStatusSelection('notAnOption');
    handleGeneralResultDialogClose();
  };

  const handleToastAfterGeneralResultSave = () => {
    setIsGeneralResultSavingToastShowing(false);
    resetGeneralResultSaveMutation();
  };

  const assessmentStatusBadge = (assessmentStatus) => {
    switch (assessmentStatus) {
      case studiesConst.moduleStatus.noStatus:
        return (
          <div className="status-field">
            <span className="ellipse figure--white" />
          </div>
        );
      case studiesConst.moduleStatus.inProgress:
        return (
          <div className="status-field">
            <span className="ellipse figure--gray" />
          </div>
        );
      case studiesConst.moduleStatus.completed:
      case studiesConst.moduleStatus.inReview:
        return (
          <div className="status-field">
            <span className="ellipse figure--blue" />
          </div>
        );
      case studiesConst.moduleStatus.accepted:
        return (
          <div className="status-field">
            <span className="ellipse figure--green" />
          </div>
        );
      case studiesConst.moduleStatus.partiallyAccepted:
        return (
          <div className="status-field">
            <span className="ellipse figure--yellow" />
          </div>
        );
      case studiesConst.moduleStatus.rejected:
        return (
          <div className="status-field">
            <span className="ellipse figure--red" />
          </div>
        );
      default:
        return (
          <div className="status-field">
            <span className="ellipse figure--white" />
          </div>
        );
    }
  };

  const applicantField = ({id, name, questionnaireId, assessmentId, modules}) => {
    return (
      <div className="flex candidate-field">
        <Link
          className="fw-semibold candidate-name-field"
          to={`${routesConst.analystAdmin}?id=${assessmentId}&userId=${id}&questionnaireId=${questionnaireId}&moduleId=logbook`}
        >
          {name}
        </Link>
        <div className="d-flex mt-3 candidate-legend-field">
          <div className="me-4 d-flex align-items-center">
            <span className="ellipse figure--white" />
            {modules.reduce(
              (total, module) =>
                module.moduleStatus === studiesConst.moduleStatus.noStatus || !module.moduleStatus
                  ? total + 1
                  : total,
              0
            )}
          </div>
          <div className="me-4 d-flex align-items-center">
            <span className="ellipse figure--gray" />
            {modules.reduce(
              (total, module) =>
                module.moduleStatus === studiesConst.moduleStatus.inProgress ? total + 1 : total,
              0
            )}
          </div>
          <div className="me-4 d-flex align-items-center">
            <span className="ellipse figure--blue" />
            {modules.reduce(
              (total, module) =>
                module.moduleStatus === studiesConst.moduleStatus.completed ||
                module.moduleStatus === studiesConst.moduleStatus.inReview
                  ? total + 1
                  : total,
              0
            )}
          </div>
          <div className="me-4 d-flex align-items-center">
            <span className="ellipse figure--green" />
            {modules.reduce(
              (total, module) =>
                module.moduleStatus === studiesConst.moduleStatus.accepted ? total + 1 : total,
              0
            )}
          </div>
          <div className="me-4 d-flex align-items-center">
            <span className="ellipse figure--yellow" />
            {modules.reduce(
              (total, module) =>
                module.moduleStatus === studiesConst.moduleStatus.partiallyAccepted
                  ? total + 1
                  : total,
              0
            )}
          </div>
          <div className="d-flex align-items-center">
            <span className="ellipse figure--red" />
            {modules.reduce(
              (total, module) =>
                module.moduleStatus === studiesConst.moduleStatus.rejected ? total + 1 : total,
              0
            )}
          </div>
        </div>
      </div>
    );
  };

  const reportFields = ({report, assessmentId, assessmentStatus, assessmentModules} = {}) => {
    const isStatusUnfinished = (status) => {
      return status < studiesConst.minCompletedStatus;
    };

    const isGeneralResultStatusUnfinished = isStatusUnfinished(assessmentStatus);

    const isAllAssessmentsModulesCompleted = assessmentModules.reduce(
      (isAssessmentCompleted, assessmentModule) =>
        isStatusUnfinished(assessmentModule.moduleStatus) ? false : isAssessmentCompleted,
      true
    );

    if (!isAllAssessmentsModulesCompleted && isGeneralResultStatusUnfinished) {
      return null;
    }

    if (isGeneralResultStatusUnfinished) {
      return null;
    }

    return (
      <div className="d-flex justify-content-center w-100 me-3">
        {reportField({
          reportType: studiesConst.reportType.executive,
          isActive: report.executiveReport,
          assessmentId,
          className: 'me-3',
        })}
        {reportField({
          reportType: studiesConst.reportType.detailed,
          isActive: report.detailedReport,
          assessmentId,
        })}
      </div>
    );
  };

  const getReportName = (reportType) => {
    switch (reportType) {
      case studiesConst.reportType.executive:
        return 'Resumen Ejecutivo';
      case studiesConst.reportType.detailed:
        return 'Reporte General';
    }
  };

  const reportField = ({assessmentId, reportType, isActive, className} = {}) => {
    return (
      <Link
        {...classNames(
          'btn btn-info report-btn',
          studiesConst.reportName[reportType],
          !isActive && 'disabled',
          className
        )}
        to={
          isActive
            ? `${serverRoutesConst.API}/assessments/${assessmentId}/download?type=${reportType}`
            : null
        }
        target="_blank"
      >
        {getReportName(reportType)}
      </Link>
    );
  };

  const columns = [
    {
      field: 'col1',
      headerName: 'Estatus',
      width: 80,
      sortable: false,
      renderCell: (params) => assessmentStatusBadge(params.value),
    },
    {
      field: 'col2',
      headerName: 'Identificación',
      width: 150,
      headerAlign: 'center',
      renderCell: (params) => (
        <div className="d-flex justify-content-center w-100 id-field">{params.value}</div>
      ),
    },
    {
      field: 'col3',
      headerName: 'Candidato',
      width: 410,
      renderCell: (params) =>
        applicantField({
          id: params.row.applicantId,
          name: params.value,
          questionnaireId: params.row.questionnaireId,
          assessmentId: params.row.assessmentId,
          modules: params.row.modules,
        }),
    },
    {field: 'col4', headerName: 'Empresa', width: 250},
    {
      field: 'col5',
      headerName: '',
      flex: 1,
      sortable: false,
      renderCell: (params) =>
        reportFields({
          report: params.value,
          assessmentId: params.row.assessmentId,
          assessmentStatus: params.row.col1,
          assessmentModules: params.row.modules,
        }),
    },
  ];

  return (
    <main className="container">
      <div className="studies-admin dashboard">
        <div className="name">Administrar Estudios</div>
        <div className="ms-4 mt-3">
          <StatusLegend
            legends={[
              {color: 'white', message: 'El candidato aún no responde'},
              {color: 'gray', message: 'El candidato aún no termina'},
              {color: 'blue', message: 'En revisión'},
            ]}
          />
        </div>
        <div className="mb-5 ms-4 mt-1">
          <StatusLegend
            legends={[
              {color: 'green', message: 'Apto para la posición a contratar'},
              {color: 'yellow', message: 'Parcialmente apto para la posición a contratar'},
              {color: 'red', message: 'Rechazado para la posición a contratar'},
            ]}
          />
        </div>
        <div className="admin-grid-controls d-flex justify-content-between">
          <div className="d-flex">
            <input
              type="text"
              className="form-control me-3 search-input"
              id="searchInput"
              name="searchInput"
              placeholder="Buscar candidato"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  searchParams.set('search', searchInput);
                  setSearchParams(searchParams);
                }
              }}
            ></input>
            <button
              type="button"
              className="btn btn-primary search-button"
              onClick={() => {
                searchParams.set('search', searchInput);
                setSearchParams(searchParams);
              }}
            >
              Buscar
            </button>
          </div>
          <div className="d-flex align-items-center me-5">
            <div className="me-3 show-label">Mostrar</div>
            <select
              id="selectPageSize"
              name="selectPageSize"
              className="form-select page-size-select"
              onChange={(event) =>
                setPaginationModel((paginationModel) => ({
                  ...paginationModel,
                  pageSize: Number(event.target.value),
                }))
              }
              value={paginationModel.pageSize}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
        <div className="study-control">
          <button
            type="button"
            {...classNames('btn', isPendingStudiesFilterActive && 'pressed')}
            onClick={() => {
              searchParams.set('reportAvailable', 'false');
              setSearchParams(searchParams);
            }}
          >
            Estudios Pendientes
          </button>
          <button
            type="button"
            {...classNames('btn', isFinishedStudiesFilterActive && 'pressed')}
            onClick={() => {
              searchParams.set('reportAvailable', 'true');
              setSearchParams(searchParams);
            }}
          >
            Estudios Evaluados
          </button>
        </div>
        <DataGrid
          rows={assessmentsRows}
          columns={columns}
          rowCount={rowCountState}
          apiRef={gridRef}
          rowHeight={113}
          paginationModel={paginationModel}
          paginationMode="server"
          pageSizeOptions={[10, 25, 50, 100]}
          loading={isLoading}
          onPaginationModelChange={setPaginationModel}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          disableColumnMenu
        />
        <Dialog open={Boolean(savingGeneralResultId)} onClose={handleGeneralResultDialogClose}>
          <div className="study-module-finalize-dialog p-4">
            <div className="d-flex align-items-center mb-3">
              <label htmlFor="generalResultStatusSelection" className="me-4">
                Causa:
              </label>
              <select
                id="generalResultStatusSelection"
                name="generalResultStatusSelection"
                className="form-select"
                onChange={(e) => setGeneralResultStatusSelection(Number(e.target.value))}
                value={generalResultStatusSelection}
              >
                <option value={'notAnOption'} disabled>
                  Seleccione una causa
                </option>
                <option value={studiesConst.moduleStatus.accepted}>
                  Apto para la posición a contratar
                </option>
                <option value={studiesConst.moduleStatus.partiallyAccepted}>
                  Parcialmente apto para la posición a contratar
                </option>
                <option value={studiesConst.moduleStatus.rejected}>
                  Rechazado para la posición a contratar
                </option>
              </select>
            </div>
            <label htmlFor="observationsTextarea" className="form-label">
              Observaciones:
            </label>
            <textarea
              id="observationsTextarea"
              name="observationsTextarea"
              className="form-control mb-4"
              onChange={(e) => setGeneralResultObservations(e.target.value)}
              value={generalResultObservations}
              rows="4"
            />
            <div className="d-flex justify-content-between save-controls">
              <button
                type="button"
                className="btn btn-primary me-4"
                onClick={handleGeneralResultDialogSave}
                disabled={typeof generalResultStatusSelection !== 'number'}
              >
                Aceptar
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleGeneralResultDialogCancel}
              >
                Cancelar
              </button>
            </div>
          </div>
        </Dialog>
        <ToastContainer className="m-4" position="top-center">
          <Toast
            className={`text-bg-${
              isGeneralResultSaveSuccessToastShowing
                ? 'success'
                : isGeneralResultSaveError
                ? 'danger'
                : 'warning'
            }`}
            show={isGeneralResultSavingToastShowing}
            onClose={handleToastAfterGeneralResultSave}
            autohide
            delay={5000}
          >
            <Toast.Body>
              {!isGeneralResultSaveError && isGeneralResultSaving && (
                <>
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Guardando estudio, por favor no cierre el navegador o la pestaña...
                </>
              )}
              {isGeneralResultSaveError &&
                'Error al guardar el estudio, verifica los campos e intenta de nuevo en unos segundos...'}
              {isGeneralResultSaveSuccessToastShowing && '¡Estudio guardado con éxito!'}
            </Toast.Body>
          </Toast>
        </ToastContainer>
      </div>
    </main>
  );
}
