import useSWR from 'swr';
import useUser from './useUser';
import {useRouteLoaderData} from 'react-router-dom';
import fetcherWithToken from '@/helpers/fetcherWithToken';

const API_ROUTE =
  'https://admin.ethicssocioeconomics.com/api/1.0/assessments';

function useAssessments(
  {id = null, page = 0, pageSize = 10, search, sort, reportAvailable},
  options = {}
) {
  const {userSession} = useRouteLoaderData('root') || {};
  const {user} = useUser();
  const {data, error, isLoading, mutate, isValidating} = useSWR(
    () =>
      user.id && user.companyId
        ? id
          ? [`${API_ROUTE}/${id}`, userSession?.accessToken?.jwtToken]
          : [
              `${API_ROUTE}/?companyId=${user.companyId}&page=${page + 1}&size=${pageSize}${
                (search && `&q=${encodeURI(search)}`) || ''
              }${(sort?.column && `&sort=${sort.direction}${sort.column}`) || ''}${
                (typeof reportAvailable !== 'undefined' && `&reportAvailable=${reportAvailable}`) ||
                ''
              }`,
              userSession?.accessToken?.jwtToken,
            ]
        : null,
    ([url, token]) => fetcherWithToken(url, token),
    options
  );

  return {
    assessments: id ? data : data?.assessments,
    totalCount: id ? 0 : data?.totalCount,
    isLoading,
    isError: error,
    mutate,
    isValidating,
  };
}

export default useAssessments;
